@import '~antd/dist/antd.css';
.site-page-header-ghost-wrapper {
  background-color: #f5f5f5;
  padding: 24px;
}

.my-modal-class {
  top: 0;
  padding-bottom: 0;
}

.my-modal-class .ant-modal-body {
  padding: 0;
}

.my-modal-class .ant-modal-footer {
  border-top: 1px solid #e8e8e8;
  position: sticky;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.ant-table-tbody>tr .ant-table-wrapper:only-child .ant-table {
  margin: 0;
}